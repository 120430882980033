import { EssGenericListItem } from "../../../shared/array-like-list/generic-list-item.class";

export interface SecondaryAnswerData {
    count?: number,
    text?: string,
    bool?: boolean,
    index?: number, /** for radio buttons , dropdown etc  */
}

/**
 * A secondary answer belongs to a choice (= the user's primary answer to a poll option).
 * A secondary answer can be one of many secondary answers. There are different types:
 *      count:  Result of a number-answer question (or text-and-number)
 *      text:   Result of a text-answer question (or text-and-number)
 *      bool:   Result of a checkbox question (many secondary answers)
 *      index:  Result of a dropdown or radio button (only one secondary answer)
 *
 */
export class SecondaryAnswer extends EssGenericListItem<SecondaryAnswerData> {
    constructor(data: SecondaryAnswerData) {
        super(data)
        if (!data) {
            this._data = {}
        }
    }

    /**
     * Creates a new instance of `SecondaryAnswer` with the provided data or dummy data if none is provided.
     *
     * @param data - The data to initialize the `SecondaryAnswer` instance with. If not provided, dummy data will be used.
     * @returns A new instance of `SecondaryAnswer`.
     */
    static newWithDummy(data: SecondaryAnswerData): SecondaryAnswer {
        data = data ? data : SecondaryAnswer.dummyData()
        return new SecondaryAnswer(data)
    }

    static dummyData() {
        return { text: "" }
    }

    get data(): SecondaryAnswerData {
        return this._data;
    }

    get count(): number | undefined {
        return this._data.count;
    }

    set count(value: number | undefined) {
        this._data.count = value;
    }

    get bool(): boolean | undefined {
        return this._data.bool;
    }

    set bool(value: boolean | undefined) {
        this._data.bool = value;
    }

    get text(): string | undefined {
        return this._data.text;
    }

    set text(value: string | undefined) {
        this._data.text = value;
    }

    get index(): number | undefined {
        return this._data.index;
    }

    set index(value: number | undefined) {
        this._data.index = value;
    }
}

