import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from '@angular/fire/compat/database';
import { Observable, firstValueFrom } from 'rxjs';
import { NGXLogger as LoggerService } from "ngx-logger";
import { PathService } from '../../core/path.service';
import { HeaderData } from 'whocan-lib';
import { CurrentPollService } from '../../core/current-poll.service';
import { map, tap } from 'rxjs/operators';
import { TemplateService } from '../../core/poll-templates/template.service';
import firebase from 'firebase/compat/app';
import { AuthService } from '../../../user/auth.service';
import { EssErrorService } from 'ngx-essentia';
import { HeaderClass } from 'whocan-lib';


@Injectable({
    providedIn: 'root'
})
/**
 * Service to persist to and load from FireDatabase;
 * keeps the reference to the header that is currently in use
 */
export class HeaderService {
    private _headerClass: HeaderClass
    private _isLoading = false;
    constructor(
        private logger: LoggerService,
        private pathService: PathService,
        private afd: AngularFireDatabase,
        private authService: AuthService,
        private currentPollService: CurrentPollService,
        private templateService: TemplateService,
        private errorService: EssErrorService
    ) {
    }

    public initNewHeader(pollTemplate, pollTitle, themeKey?: string) {
        this._headerClass = new HeaderClass(
            this.templateService.getDefaultSettings(),
            pollTemplate,
            pollTitle,
            themeKey
        )
    }

    get headerClass(): HeaderClass {
        return this._headerClass
    }

    get observable(): Observable<HeaderData> {
        const ref: AngularFireObject<HeaderData> = this.afd.object(this.pathService.getHeaderPath());
        return ref.valueChanges().pipe(
            map(headerData => {
                return headerData
            }));
    }

    public async load() {
        if (!this._isLoading) {
            this._isLoading = true;
            this._headerClass = new HeaderClass(await firstValueFrom(this.observable))
            this._headerClass.resetDataSinceLastPersistence()
            this.templateService.initTemplate(this.headerClass.pollTemplate);
            this._isLoading = false;
        }
    }

    get isLoading(): boolean {
        return this._isLoading
    }

    public async persist(): Promise<void> {
        this.logger.log('persist: ', this.headerClass);
        this.headerClass.ownerName = this.authService.displayName
        if (!this.headerClass.createdAt) { this.headerClass.createdAt = firebase.database.ServerValue.TIMESTAMP; } // if its a new header we need to set the createdAt
        this.headerClass.updatedAt = firebase.database.ServerValue.TIMESTAMP;
        if (this.headerClass.ownerId && this.currentPollService.getOwnerId() !== this.headerClass.ownerId) {
            throw this.errorService.newError('Owner ID inconsistent')
        }
        if (this.headerClass.pollId && this.currentPollService.getPollId() !== this.headerClass.pollId) {
            throw this.errorService.newError('Poll ID inconsistent')
        }
        if (!this.headerClass.pollTitle) { this.headerClass.pollTitle = 'N.N.' }
        this.headerClass.resetDataSinceLastPersistence()
        const ref: AngularFireObject<HeaderData> = this.afd.object(this.pathService.getHeaderPath());
        await ref.update(this.headerClass.headerData);
    }

    public getNewHeaderKey(ownerId?: string): string {
        const newKey = this.afd.list(this.pathService.getPollsOwnedPath(ownerId)).push({}).key; // this call returns a new key but does not persist because the object is empty
        this.logger.log('getNewHeaderKey - key: ' + newKey);
        return newKey;
    }


    public async headerExist(ownerId: string, pollId: string): Promise<boolean> {
        this.logger.log('headerExists? start; ownerId: ' + ownerId + ' surveId: ' + pollId);
        const promise = new Promise<boolean>(async (resolve, reject) => {
            try {
                const ref = this.afd.database.ref(this.pathService.getHeaderPath(ownerId, pollId));
                ref.once('value', snapshot => {
                    if (snapshot.exists()) {
                        this.logger.log('headerExists exists: ');
                        resolve(true);
                    } else {
                        this.logger.log('headerExists doesn\'t exist');
                        resolve(false);
                    }
                });
            } catch (error) {
                const errorMessage = 'Could not reach server. ' + error;
                this.logger.error(errorMessage);
                reject(error);
            }
        });
        return promise;
    }

    /**
     * @returns new pollId
     */
    public async copyHeader(ownerId: string, pollId: string, newOwnerId: string, newOwnerName: string): Promise<string> {
        this.logger.log('Exists? start; ownerId: ' + ownerId + ' surveId: ' + pollId);
        const promise = new Promise<string>(async (resolve, reject) => {
            try {
                const ref = this.afd.database.ref(this.pathService.getHeaderPath(ownerId, pollId));
                ref.once('value', async snapshot => {
                    if (snapshot.exists()) {
                        this.logger.log('Survey exists: ');
                        const newSurveyId = await this.copyAfdHeaderObject(snapshot.val(), newOwnerId, newOwnerName);
                        resolve(newSurveyId);
                    } else {
                        this.logger.warn('Survey doesn\'t exist');
                        resolve(null);
                    }
                });
            } catch (error) {
                const errorMessage = 'Could not reach server. ' + error;
                this.logger.error(errorMessage);
                reject(error);
            }
        });
        return promise;
    }

    /**
     * @returns the new pollId
     */
    private async copyAfdHeaderObject(oldObject: HeaderData, newOwnerId: string, newOwnerName: string): Promise<string> {
        const newPollId = await this.getNewHeaderKey(newOwnerId);
        const newObject = { ...oldObject, };
        newObject.pollTitle = 'Copy of ' + oldObject.pollTitle;
        newObject.ownerId = newOwnerId;
        newObject.ownerName = newOwnerName;
        newObject.pollId = newPollId;
        newObject.votesCounter = 0;
        newObject.updatedAt = firebase.database.ServerValue.TIMESTAMP;
        newObject.createdAt = firebase.database.ServerValue.TIMESTAMP;
        const ref: AngularFireObject<HeaderData> = this.afd.object(this.pathService.getHeaderPath(newOwnerId, newPollId));
        await ref.update(newObject);
        this.logger.log('copyAFDHeaderObject: new object with ID: ' + newPollId + ' has been created.');
        return newPollId;
    }

    public deleteHeader(ownerId: string, pollId: string) {
        const ref: AngularFireObject<any> = this.afd.object(this.pathService.getHeaderPath(ownerId, pollId));
        ref.remove();
        this.logger.log('deleteHeader: ownerId:  ' + ownerId + '; pollId: ' + pollId);
    }

}
