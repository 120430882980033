import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { EssGenericListItem } from './generic-list-item.class';

/**
 * A generic list class to support reading and writing arrays from firebase database
 * This class provides methods to manipulate a list of items that extend `EssGenericListItem`.
 *
 * @template ItemClass - The type of items in the list, extending `EssGenericListItem`.
 * @template ItemData - The type of data contained in each item.
 */
export abstract class EssGenericList<ItemClass extends EssGenericListItem<ItemData>, ItemData> implements Iterable<ItemClass>, ArrayLike<ItemClass> {
    [n: number]: ItemClass;

    constructor(itemArray?: ItemClass[]) {
        itemArray?.forEach((item, index) => {
            this[index] = cloneDeep(item)
        });
    }

    /**
     * Deletes an item from the list at the specified index.
     *
     * @param index - The index of the item to delete.
     * @returns The deleted item if the index is valid, otherwise `undefined`.
     */
    deleteItem(index: number): ItemClass | undefined {
        const length = this.length;
        if (index < 0 || index >= length) return undefined;
        const deletedItem = this[index];
        for (let i = index; i < length - 1; i++) {
            this[i] = this[i + 1];
        }
        delete this[length - 1];
        return deletedItem;
    }

    /**
     * Reinitializes the list with new items created by the provided item factory function.
     * Clears the current list and populates it with new items based on the provided data array.
     * Intended for creating a list from a data array
     *
     * @param itemFactory - A function that takes an item data object and returns a new instance of ItemClass.
     * @param dataArray - An array of data objects used to create new instances of ItemClass.
     * @param length - the minimum length of the list
     */
    reInit(itemFactory: (data?: ItemData) => ItemClass, dataArray: ItemData[], length?: number): EssGenericList<ItemClass, ItemData> {
        Object.keys(this).forEach(key => delete this[key]);
        dataArray?.forEach((data, index) => {
            this[index] = itemFactory(data)
        })
        this.init(length, itemFactory())
        return this
    }

    /**
     * Initializes the list with a specified length, optionally filling it with a default object.
     * Does not overwrite or delete existing items in the list
     *
     * @param length - The minimum length of the list after initialization.
     * @param defaultObject - An optional object to clone and use as the default value for new elements.
     * @param defaultObjectManipulator - An optional function to manipulate each new object.
     */
    init(length: number, defaultObject: ItemClass, defaultObjectManipulator?: (obj: ItemClass, index: number) => void) {
        for (let i = this.length; i < length; i++) {
            const newObj = cloneDeep(defaultObject)
            if (defaultObjectManipulator) {
                defaultObjectManipulator(newObj, i);
            }
            this.push(newObj);
        }
    }


    abstract get data(): ItemData[]

    public push(...items: ItemClass[]): number {
        let length = this.length;
        for (const item of items) {
            this[length++] = item;
        }
        return length;
    }

    public pop(): ItemClass | undefined {
        const length = this.length;
        if (length === 0) return undefined;
        const lastItem = this[length - 1];
        delete this[length - 1];
        return lastItem;
    }

    public map<U>(callback: (value: ItemClass, index: number, array: ItemClass[]) => U): U[] {
        const result: U[] = [];
        for (let i = 0; i < this.length; i++) {
            result.push(callback(this[i], i, Object.values(this)));
        }
        return result;
    }


    public find(callback: (value: ItemClass, index: number, array: ItemClass[]) => boolean): ItemClass | undefined {
        for (let i = 0; i < this.length; i++) {
            if (callback(this[i], i, Object.values(this))) {
                return this[i];
            }
        }
        return undefined;
    }

    get length() {
        return Object.values(this).length;
    }

    cloneDeep(): EssGenericList<ItemClass, ItemData> {
        return cloneDeep(this);
    }

    isEqual(other: EssGenericList<ItemClass, ItemData>): boolean {
        return isEqual(this, other);
    }

    [Symbol.iterator](): Iterator<ItemClass> {
        let index = 0;
        const itemArray = Object.values(this);
        return {
            next(): IteratorResult<ItemClass> {
                if (index < itemArray.length) {
                    const value = itemArray[index];
                    index++;
                    return { value, done: false };
                } else {
                    return { value: null, done: true };
                }
            }
        };
    }

    public forEach(callback: (value: ItemClass, index: number, array: ItemClass[]) => void): void {
        for (let i = 0; i < this.length; i++) {
            callback(this[i], i, Object.values(this));
        }
    }
}

