import { EssGenericList } from "../../shared/array-like-list/generic-list.class";
import { ChoiceClass, ChoiceData, ChoiceEnum } from "./choice.class";


export class ChoiceList extends EssGenericList<ChoiceClass, ChoiceEnum | ChoiceData> {

    get data(): (ChoiceEnum | ChoiceData)[] {
        return Object.values(this).map(item => item.data)
    }
}
