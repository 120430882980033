import { EssGenericListItem } from "../../shared/array-like-list/generic-list-item.class";
import cloneDeep from 'lodash/cloneDeep';
import { SecondaryAnswer, SecondaryAnswerData } from "./secondary-answer/secondary-answer.class";
import { SecondaryAnswerList } from "./secondary-answer/secondary-answer-list.class";

export enum ChoiceEnum {
    yes = 'YES',
    no = 'NO',
    mayBe = 'MAYBE',
    notSelected = 'NOT_SELECTED'
}


export interface ChoiceData {
    choiceEnum: ChoiceEnum;
    count?: number; /**  not in use? */
    text?: string  /**  not in use? */
    secondaryAnswerArray?: SecondaryAnswerData[]
    adultsCount?: number; /** @deprictated: can be handled using the number answer */
    kidsCount?: number; /** @deprictated: can be handled using the number answer */
    adultVegetariansCount?: number; /** @deprictated: can be handled using the number answer */
    kidVegetariansCount?: number; /** @deprictated: can be handled using the number answer */
    comment?: string; /** @todo make it a seperate objectArray - a user may add various comments, it also needs a date with it*/
}

/**
 * While a vote is one user's response to a poll,
 * a vote consists of header attributes and a list of choices (answers).
 * Each choice (answer) can have a list of secondary answers.
 * Each choice corresponds to an option (question) of the poll.

 */
export class ChoiceClass extends EssGenericListItem<ChoiceData> {
    private _secondaryAnswerList: SecondaryAnswerList
    constructor(choice?: ChoiceEnum | ChoiceData) {
        super(ChoiceClass.convertToChoiceData(choice))
        SecondaryAnswerList.repairMissingIndex(this._data.secondaryAnswerArray)
        this._secondaryAnswerList = new SecondaryAnswerList().reInit(SecondaryAnswer.newWithDummy, this._data.secondaryAnswerArray, 1);

    }

    get data(): ChoiceData {
        let data: ChoiceData = cloneDeep(this._data)
        data.secondaryAnswerArray = { ... this.secondaryAnswerList.data }
        return data
    }

    initSecondaryAnswerArray(length: number, defaultData?: SecondaryAnswerData) {
        const defaultObject = SecondaryAnswer.newWithDummy(defaultData)
        this._secondaryAnswerList.init(length, defaultObject)
    }

    addItemToSecondaryAnswerArray(defaultData?: SecondaryAnswerData) {
        this._secondaryAnswerList.push(new SecondaryAnswer(defaultData))
    }

    get secondaryAnswerList(): SecondaryAnswerList {
        return this._secondaryAnswerList
    }

    get choiceEnum(): ChoiceEnum {
        return this._data.choiceEnum
    }

    set choiceEnum(value: ChoiceEnum) {
        this._data.choiceEnum = value
    }



    get adultsCount(): number {
        return this._data.adultsCount
    }

    set adultsCount(value: number) {
        this._data.adultsCount = value;
    }

    get kidsCount(): number {
        return this._data.kidsCount
    }

    set kidsCount(value: number) {
        this._data.kidsCount = value;
    }

    get adultVegetariansCount(): number {
        return this._data.adultVegetariansCount
    }

    set adultVegetariansCount(value: number) {
        this._data.adultVegetariansCount = value;
    }

    get kidVegetariansCount(): number {
        return this._data.kidVegetariansCount
    }

    set kidVegetariansCount(value: number) {
        this._data.kidVegetariansCount = value;
    }

    get comment(): string {
        return this._data.comment
    }

    set comment(value: string) {
        this._data.comment = value;
    }

    get isNo(): boolean {
        return ChoiceClass.isNo(this.choiceEnum)
    }

    get isYes(): boolean {
        if (this.choiceEnum === ChoiceEnum.yes) return true;
        return false
    }

    get isMaybe(): boolean {
        if (this.choiceEnum === ChoiceEnum.mayBe) return true;
        return false
    }

    private static convertToChoiceData(choice?: ChoiceEnum | ChoiceData | undefined): ChoiceData {
        if (!choice) {
            return { choiceEnum: ChoiceEnum.notSelected }
        }
        if (ChoiceClass.isOfTypeChoiceEnum(choice)) {
            return { choiceEnum: <ChoiceEnum>choice }
        }
        return <ChoiceData>choice
    }

    public static isOfTypeChoiceEnum(choice: ChoiceEnum | ChoiceData | undefined): boolean {
        return choice !== undefined && typeof choice === 'string'
    }

    public static isNo(pollChoice: ChoiceEnum): boolean {
        if (pollChoice === ChoiceEnum.yes || pollChoice === ChoiceEnum.mayBe) {
            return false
        }
        return true
    }

    public static isYes(pollChoice: ChoiceEnum): boolean {
        if (pollChoice === ChoiceEnum.yes) {
            return true
        }
        return false
    }

    public static isOfTypeChoiceData(choice: ChoiceEnum | ChoiceData | undefined): boolean {
        return choice !== undefined && typeof choice === 'object'
    }


}
