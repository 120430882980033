import { TimePeriodClass, TimePeriodData } from "./time-period.class";


export interface HeaderData {
    updatedAt?: any;
    createdAt?: any;
    votesCounter?: number;
    ownerId?: string;
    ownerName?: string;
    pollId?: string;
    pollTemplate?: string;
    pollTitle?: string;
    pollDescription?: string;
    pollLongDescription?: string;
    limitPerUser?: boolean;
    limitPerOption?: number;
    allowToAddOptions?: boolean;
    getNotifications?: boolean;
    notificationRecipient?: string; //the user id that should receive the noticfication; can be different from ownerId if the poll is delegate
    showAdvancedSettings?: boolean;
    showRegisterPeople?: boolean;
    showWhenAdvanced?: boolean;
    showLocationAdvanced?: boolean;
    when?: TimePeriodData;
    showLocation?: boolean;
    location?: string;
    showRespondTill?: boolean;
    respondTill?: TimePeriodData; // startHour  and endHour not in use
    askForMultiplePersons?: boolean
    showChooseTheme?: boolean;
    theme?: string
    askForKids?: boolean;
    askForVegetarians?: boolean;
    askForEmailAdress?: boolean;
    askForName?: boolean;
    askForPhoneNumber?: boolean;

    hideParticipants?: boolean;/**depricated */
    showParticipants?: boolean;
    askForPrivateComments?: boolean;
    askForPublicComments?: boolean;
    allowParticipantsNotifications?: boolean;

}
export class HeaderClass {
    private _headerDataSinceLastPersistence: string;
    private _headerData: HeaderData;
    constructor(
        headerData: HeaderData,
        pollTemplate?: string,
        pollTitle?,
        themeKey?: string
    ) {
        if (!headerData) throw new Error('!headerData')
        this.headerData = headerData// using the setter hence copy by value
        if (pollTemplate) this._headerData.pollTemplate = pollTemplate
        if (pollTitle) this._headerData.pollTitle = pollTitle
        if (themeKey) this._headerData.theme = themeKey
        this.setShowParticipantsDefault()
    }

    /**
     * used to transform old polls not  using attribute 'showParticipants' but 'showParticipants'
     */
    private setShowParticipantsDefault() {
        if (this._headerData.showParticipants === undefined) {
            if (this._headerData.hideParticipants === undefined) {
                this._headerData.showParticipants = true
            } else if (this._headerData.hideParticipants === false) {
                this._headerData.showParticipants = true
            } else if (this._headerData.hideParticipants === true) {
                this._headerData.showParticipants = true;
            }
        } else {

        }
    }

    get headerData(): HeaderData {
        return this._headerData
    }
    set headerData(headerData: HeaderData) {
        this._headerData = {
            ...headerData,
            when: { ...headerData.when },
            respondTill: { ...headerData.respondTill }
        }
    }
    get updatedAt(): any {
        return this._headerData.updatedAt;
    }
    set updatedAt(value: any) {
        this._headerData.updatedAt = value;
    }
    get createdAt(): any {
        return this._headerData.createdAt;
    }
    set createdAt(value: any) {
        this._headerData.createdAt = value;
    }
    get votesCounter(): number {
        if (this._headerData.votesCounter) {
            return this._headerData.votesCounter;
        } else {
            return 0
        }
    }
    set votesCounter(value: number) {
        this._headerData.votesCounter = value;
    }
    get ownerId(): string {
        return this._headerData.ownerId;
    }
    set ownerId(value: string) {
        this._headerData.ownerId = value;
    }
    get ownerName(): string {
        return this._headerData.ownerName;
    }
    set ownerName(value: string) {
        this._headerData.ownerName = value;
    }
    get pollId(): string {
        return this._headerData.pollId;
    }
    set pollId(value: string) {
        this._headerData.pollId = value;
    }
    get pollTemplate(): string {
        return this._headerData.pollTemplate;
    }
    set pollTemplate(value: string) {
        this._headerData.pollTemplate = value;
    }
    get pollTitle(): string {
        return this._headerData.pollTitle;
    }
    set pollTitle(value: string) {
        this._headerData.pollTitle = value;
    }
    get pollDescription(): string {
        return this._headerData.pollDescription;
    }
    set pollDescription(value: string) {
        this._headerData.pollDescription = value;
    }
    get pollLongDescription(): string {
        return this._headerData.pollLongDescription;
    }
    set pollLongDescription(value: string) {
        this._headerData.pollLongDescription = value;
    }
    get limitPerUser(): boolean {
        return this._headerData.limitPerUser;
    }

    set limitPerUser(value: boolean) {
        this._headerData.limitPerUser = value;
    }

    get limitPerOption(): number {
        return this._headerData.limitPerOption;
    }

    set limitPerOption(value: number) {
        this._headerData.limitPerOption = value;
    }

    /**@deprecated */
    get hideParticipants(): boolean {
        return this._headerData.hideParticipants;
    }

    /**@deprecated */
    set hideParticipants(value: boolean) {
        this._headerData.hideParticipants = value;
    }

    get showParticipants(): boolean {
        return this._headerData.showParticipants;
    }
    set showParticipants(value: boolean) {
        this._headerData.showParticipants = value;
    }

    get allowParticipantsNotifications(): boolean {
        if (!this.showParticipants) return false;
        return this._headerData.allowParticipantsNotifications;
    }
    set allowParticipantsNotifications(value: boolean) {
        this._headerData.allowParticipantsNotifications = value;
    }

    get askForPublicComments(): boolean {
        if (!this.showParticipants) return false;
        return this._headerData.askForPublicComments;
    }
    set askForPublicComments(value: boolean) {
        this._headerData.askForPublicComments = value;
    }

    get allowToAddOptions(): boolean {
        return this._headerData.allowToAddOptions;
    }

    set allowToAddOptions(value: boolean) {
        this._headerData.allowToAddOptions = value;
    }

    get getNotifications(): boolean {
        return this._headerData.getNotifications;
    }

    set getNotifications(value: boolean) {
        this._headerData.getNotifications = value;
    }

    get notificationRecipient(): string {
        return this._headerData.notificationRecipient;
    }

    set notificationRecipient(value: string) {
        this._headerData.notificationRecipient = value;
    }

    get showAdvancedSettings(): boolean {
        return this._headerData.showAdvancedSettings;
    }

    set showAdvancedSettings(value: boolean) {
        this._headerData.showAdvancedSettings = value;
    }

    get showRegisterPeople(): boolean {
        return this._headerData.showRegisterPeople;
    }

    set showRegisterPeople(value: boolean) {
        this._headerData.showRegisterPeople = value;
    }

    get showWhenAdvanced(): boolean {
        return this._headerData.showWhenAdvanced;
    }

    set showWhenAdvanced(value: boolean) {
        this._headerData.showWhenAdvanced = value;
    }

    get showLocationAdvanced(): boolean {
        return this._headerData.showLocationAdvanced;
    }

    set showLocationAdvanced(value: boolean) {
        this._headerData.showLocationAdvanced = value;
    }

    get when(): TimePeriodClass {
        return new TimePeriodClass(this._headerData.when);
    }

    set when(value: TimePeriodClass) {
        this._headerData.when = { ...value.timePeriodData };
    }
    get respondTill(): TimePeriodClass {
        return new TimePeriodClass(this._headerData.respondTill);
    }

    set respondTill(value: TimePeriodClass) {
        this._headerData.respondTill = { ...value.timePeriodData };
    }

    get showLocation(): boolean {
        return this._headerData.showLocation;
    }

    set showLocation(value: boolean) {
        this._headerData.showLocation = value;
    }

    get location(): string {
        return this._headerData.location;
    }
    set location(value: string) {
        this._headerData.location = value;
    }
    get showRespondTill(): boolean {
        return this._headerData.showRespondTill;
    }
    set showRespondTill(value: boolean) {
        this._headerData.showRespondTill = value;
    }

    get askForMultiplePersons(): boolean {
        return this._headerData.askForMultiplePersons;
    }
    set askForMultiplePersons(value: boolean) {
        this._headerData.askForMultiplePersons = value;
    }
    get showChooseTheme(): boolean {
        return this._headerData.showChooseTheme;
    }
    set showChooseTheme(value: boolean) {
        this._headerData.showChooseTheme = value;
    }
    get theme(): string {
        return this._headerData.theme;
    }
    set theme(value: string) {
        this._headerData.theme = value;
    }
    get askForKids(): boolean {
        return this._headerData.askForKids;
    }
    set askForKids(value: boolean) {
        this._headerData.askForKids = value;
    }
    get askForVegetarians(): boolean {
        return this._headerData.askForVegetarians;
    }
    set askForVegetarians(value: boolean) {
        this._headerData.askForVegetarians = value;
    }
    get askForEmailAdress(): boolean {
        return this._headerData.askForEmailAdress;
    }
    set askForEmailAdress(value: boolean) {
        this._headerData.askForEmailAdress = value;
    }
    get askForName(): boolean {
        if (this._headerData.askForName === undefined) {
            return true;
        }
        return this._headerData.askForName;
    }
    set askForName(value: boolean) {
        this._headerData.askForName = value;
    }
    get askForPhoneNumber(): boolean {
        return this._headerData.askForPhoneNumber;
    }
    set askForPhoneNumber(value: boolean) {
        this._headerData.askForPhoneNumber = value;
    }
    get askForPrivateComments(): boolean {
        return this._headerData.askForPrivateComments;
    }
    set askForPrivateComments(value: boolean) {
        this._headerData.askForPrivateComments = value;
    }


    public uninitialize() {
        this._headerData = null
    }

    get hasDescription() {
        if (this._headerData?.pollDescription && this._headerData?.pollDescription.length > 2) return true;
        return false
    }

    public resetDataSinceLastPersistence() {
        this._headerDataSinceLastPersistence = JSON.parse(JSON.stringify(this.headerData))
    }

    public hasChangedSinceLastPersitance(): boolean {
        return (JSON.stringify(this.headerData) !== JSON.stringify(this._headerDataSinceLastPersistence))
    }


}
