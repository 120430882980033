import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from '@angular/fire/compat/database';
import { Observable } from 'rxjs';
import { NGXLogger as LoggerService } from "ngx-logger";
import { PathService } from '../../core/path.service';

import { CurrentPollService } from '../../core/current-poll.service';
import firebase from 'firebase/compat/app';
import { AuthService } from '../../../user/auth.service';
import { ParticipationData } from 'whocan-lib';


@Injectable({
    providedIn: 'root'
})
/**
 * Service to persist to and load from FireDatabase;
 * Functions to work with the Option interface
 * Provides also the PollsOwnedObservable and PollsParticipatedObservable (maybe better in seperate sesrvices)
 */
export class PollParticipantsService {
    constructor(
        private logger: LoggerService,
        private pathService: PathService,
        private afd: AngularFireDatabase,
        private authService: AuthService,
        private currentPollService: CurrentPollService,
    ) {

    }

    /**
     * Should be called any time a users persist a vote
     * Stores the participation seperatly from the poll to get the surveys that a user has participated in easily
     * @param ownerId
     * @param pollId
     */
    public updateParticipants(ownerId: string, ownerName: string, pollId: string, pollTitle: string, userName: string): void {// todo move to own service
        this.logger.log('updateParticipants', ownerId, ownerName)
        // pollTitle is a copy hence it will not update but that's ok
        // The user that has participated will see the title of the poll of the time he has votes
        if (!pollTitle) { pollTitle = "" }
        ownerName = ownerName ? ownerName : "";
        let participatedUserId: string;
        if (this.currentPollService.voteUserId) {
            participatedUserId = this.currentPollService.voteUserId;
        } else {
            participatedUserId = this.authService.uid;
        }
        const particpation: ParticipationData = { participatedUserId: participatedUserId, userName: userName, ownerId: ownerId, ownerName: ownerName, pollId: pollId, pollTitle: pollTitle };
        particpation.updatedAt = firebase.database.ServerValue.TIMESTAMP;
        const ref: AngularFireObject<ParticipationData> = this.afd.object(`${this.pathService.getSurveyParticipatedPath(ownerId, pollId, participatedUserId)}`);
        this.logger.log('updatePartipants: ', particpation);
        ref.update(particpation);
    }


    public async deleteParticipation(ownerId: string, pollId: string, userId: string): Promise<void> {
        const ref: AngularFireObject<any> = this.afd.object(this.pathService.getSurveyParticipatedPath(ownerId, pollId, userId));
        ref.remove();
        this.logger.log('deleteParticipation', ownerId, pollId, userId);

    }
}
