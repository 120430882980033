import { repairMissingIndexes } from "../../../shared/utils/utils";
import { EssGenericList } from "../../../shared/array-like-list/generic-list.class";
import { SecondaryAnswer, SecondaryAnswerData } from "./secondary-answer.class";



export class SecondaryAnswerList extends EssGenericList<SecondaryAnswer, SecondaryAnswerData> {

    static repairMissingIndex(dataArray: SecondaryAnswerData[]) {
        repairMissingIndexes<SecondaryAnswerData>(dataArray, SecondaryAnswer.dummyData())
    }

    get data(): SecondaryAnswerData[] {
        return Object.values(this).map(item => item.data)
    }
}
