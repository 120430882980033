import cloneDeep from 'lodash/cloneDeep';

/**
 * Repairs missing indexes in the given data array in place by inserting dummy items.
 *
 * @template T - The type of items in the data array.
 * @param {T[]} dataArray - The array to be checked and repaired.
 * @param {T} dummyItem - The dummy item to insert for missing indexes.
 * @returns {boolean} - Returns true if the array was corrupt and needed repair, otherwise false.
 */
export function repairMissingIndexes<T>(dataArray: T[], dummyItem: T): boolean {
    let isCorrupt = false
    if (dataArray) {
        const deepClone = cloneDeep(dataArray)
        const dummyClone = cloneDeep(dummyItem)
        dataArray.length = 0
        if (dataArray) {
            let highestArrayIndex = getHighestArrayIndex(deepClone)
            for (let i = 0; i <= highestArrayIndex; i++) {
                const data = deepClone[i];
                if (data) {
                    dataArray.push(data);
                } else { // missing index in the array
                    isCorrupt = true
                    dataArray.push(dummyClone) // add a dummy for the missing element
                }
            }
        }
    }
    return isCorrupt
}

/**
 * Synchronizes the indexes of elements in the array
 *
 * @param {any[]} array - The array whose elements' indexes need to be synchronized.
 * @param {string} indexKey - The key in each element that holds the index value.
 * @returns {boolean} - Returns true if any indexes were corrected, otherwise false.
 */
export function syncIndex(array: any[], indexKey: string): boolean {
    let isCorrupt = false
    if (array) {
        for (let i = 0; i < array.length; i++) {
            if (array[i]) {
                if (array[i][indexKey] !== i) {
                    array[i][indexKey] = i;
                    isCorrupt = true
                }
            }
        }
    }
    return isCorrupt
}

/**
 * Finds the  highest index in an 'array' of objects, even if the array has missing indices or indexes that ar not a number
 * Useful to deal with potential currupt data coming from firebase that should come as an array but is actrually a map
 *
 * @param {any[]} dataArray - The array to be checked.
 * @returns {number} - The highest index found in the array.
 */
export function getHighestArrayIndex(dataArray: any[]): number {
    let highestArrayIndex = 0
    if (dataArray) {
        for (const key in dataArray) {
            if (dataArray.hasOwnProperty(key)) {
                const index = Number(key)
                if (!isNaN(index) && index > highestArrayIndex) highestArrayIndex = index;
            }
        }
    }
    return highestArrayIndex
}

/**
 * Truncates a string to a specified maximum length, adding ellipsis if the string exceeds the maximum length.
 *
 * @param {string} value - The string to be truncated.
 * @param {number} maxLength - The maximum length of the truncated string.
 * @returns {string} - The truncated string with ellipsis if it exceeds the maximum length.
 */
export function truncateString(value: string, maxLength: number): string {
    if (value.length <= maxLength) {
        return value;
    }
    return value.substring(0, maxLength) + '...';
}

